import { Checkbox } from '@pulse-web-ui/checkbox';
import { styled } from '@pulse-web-ui/theme';

export const FiltersArrayList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StyledCheckbox = styled(Checkbox)`
  & svg {
    color: ${({ theme }) => theme.colors.text.inverse};
  }
`;
