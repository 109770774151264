import { styled, typography } from '@pulse-web-ui/theme';
import { Input } from '@pulse-web-ui/input';
import { IconButton } from '../..';

export const StyledInput = styled(Input)`
  ${typography.regular14}
  height: 40px;
  background-color: ${({ theme }) => theme.colors.surface.primary};
  border-radius: calc(${({ theme }) => theme.common.radius} * 2) !important;
  border: none;
  line-height: 20px;

  &::placeholder {
    opacity: 1;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.surface.primary};
    border: none;

    &::placeholder {
      opacity: 1;
    }
  }

  &:focus {
    border: none;
  }

  & ~ span {
    top: 0;
    right: 0;
  }
`;

export const SearchButton = styled(IconButton)<{ disabled?: boolean }>`
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.neutral[20] : theme.colors.surface.accent};
  border-radius: calc(${({ theme }) => theme.common.radius} * 2);
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
`;
