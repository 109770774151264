import { useTranslation } from 'react-i18next';
import { ChangePhoneButton } from './change-phone.style';
import { addTestAttribute } from '@shared/index';

type ChangePhoneProps = {
  navigateToStepOne: () => void;
};
export const ChangePhone = ({ navigateToStepOne }: ChangePhoneProps) => {
  const { t } = useTranslation();

  return (
    <ChangePhoneButton
      onClick={navigateToStepOne}
      {...addTestAttribute('otp-button-change-phone')}
    >
      {t('CHANGE_PHONE:buttons.сhangePhone')}
    </ChangePhoneButton>
  );
};
