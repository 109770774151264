import { media, styled } from '@pulse-web-ui/theme';

export const VirtualScrollListWrapper = styled.div`
  width: 100%;
  overflow: auto;
  height: calc(100vh - 88px);
  margin-top: -16px;
  padding-top: 16px;
`;

export const VirtualScrollListAllItems = styled.div<{ height: number }>`
  height: ${({ height }) => `${height}px`};
  width: 100%;
  position: relative;
  // Workaround for iOS
  padding-bottom: 200px;

  ${media.desktop} {
    padding-bottom: 0;
  }
`;

export const VirtualScrollListVisibleItems = styled.div<{ start: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: ${({ start }) => `translateY(${start}px)`};
`;
