export enum AnalyticEvent {
  AGENT_AUTH_OPEN = 'agent_auth_open',
  AGENT_AUTH_SIGNUP = 'agent_auth_signup',
  AGENT_REG_OPEN = 'agent_reg_open',
  AGENT_REG_TO_STEP_OTP = 'agent_reg_tostep_OTP',
  AGENT_REG_OTP_OPEN = 'agent_reg_OTP_open',
  AGENT_REG_COMPLITED = 'agent_reg_complited',
  AGENT_NEWPRODUCT = 'agent_newproduct', // isagent = n (наличие агентского договора n = нет договора)
  AGENT_MOREDETAILS = 'agent_moredetails', // isagent = n (наличие агентского договора n = нет договора)
  AGENT_NEWAGREEMENT = 'agent_newagreement',
  AGENT_MAIN_OPEN = 'agent_main_open', // isagent = n isagent = y (наличие агентского договора n = нет договора)
  AGENT_AGREEMENT_TOSTEP_DOCS = 'agent_agreement_tostep_docs',
  AGENT_NOAGREEMENT_OPEN = 'agent_noagreement_open',
  AGENT_AGREEMENT_DOCS_OPEN = 'agent_agreement_docs_open',
  AGENT_AGREEMENT_TOSTEP_ADRESS = 'agent_agreement_tostep_adress',
  AGENT_AGREEMENT_ADRESS_OPEN = 'agent_agreement_adress_open',
  AGENT_AGREEMENT_TOSTEP_REQUISITES = 'agent_agreement_tostep_requisites',
  AGENT_AGREEMENT_REQUISITE_SOPEN = 'agent_agreement_requisites_open',
  AGENT_AGREEMENT_TOSTEP_FORMS = 'agent_agreement_tostep_forms',
  AGENT_AGREEMENT_FORMS_OPEN = 'agent_agreement_forms_open',
  AGENT_AGREEMENT_CHANGEDATA = 'agent_agreement_changedata',
  AGENT_AGREEMENT_AGREEMENT_FORM = 'agent_agreement_agreement_form',
  AGENT_AGREEMENT_TERMS_FORM = 'agent_agreement_terms_form',
  AGENT_AGREEMENT_PDN_FORM = 'agent_agreement_pdn_form',
  AGENT_AGREEMENT_TOSTEP_SIGN = 'agent_agreement_tostep_sign',
  AGENT_AGREEMENT_OTP_OPEN = 'agent_agreement_OTP_open',
  AGENT_AGREEMENT_COMPLITED = 'agent_agreement_complited',
  AGENT_AGREEMENT_GOTOMAIN = 'agent_agreement_gotomain',

  // "Мои продажи" клик на кнопку “найти” при поиске по фамилии или телефону клиента
  AGENT_SALES_SEARCH = 'agent_sales_search',
  // "Мои продажи" клик по кнопке “Фильтры“ на экране Мои продажи для открытия модального окна
  AGENT_SALES_FILTER_TAP = 'agent_sales_filter_tap',
  // "Мои продажи" клик по кнопке “Скачать“ для загрузки результатов поиска списка продаж
  AGENT_SALES_DOWNLOAD = 'agent_sales_download',
  // "Мои продажи" клик по кнопке "Применить" в модальном окне фильтров
  AGENT_SALES_FILTER_DONE = 'agent_sales_filter_done',
  // Header: клик на кнопку “Мои продажи” в модальном окне меню
  AGENT_SALES_M = 'agent_sales_m',
  // Header: клик по кнопке “Акты“ меню
  AGENT_STATEMENT_M = 'agent_statement_m',
  // Header: клик по кнопке “О продуктах “Пульс”“ в меню
  AGENT_ABOUTPRODUCTS_M = 'agent_aboutproducts_m',
  // Header: клик по кнопке "Контакты" в меню
  AGENT_CONTACTS_M = 'agent_contacts_m',
  // Header: клик по кнопке "Выйти" в меню
  AGENT_EXIT_M = 'agent_exit_m',
}
