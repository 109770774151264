import { styled } from '@pulse-web-ui/theme';

export const StyledContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const StyledText = styled.span`
  text-align: left;
  color: ${({ theme }) => theme.colors.text.primary};
`;
