export const ACCOUNT_MAX_LENGTH = 20;
export const NAME_BANK_MAX_LENGTH = 100;
export const ACCOUNT_PLACEHOLDER = '00000 000 0 0000 0000000';
export const BIC_PLACEHOLDER = '000000000';
export const ACCOUNT_MASK = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  ' ',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  ' ',
  /[0-9]/,
  ' ',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  ' ',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];
