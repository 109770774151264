import { styled } from '@pulse-web-ui/theme';

export const FiltersIndicator = styled.span`
  position: absolute;
  width: 7px;
  height: 7px;
  background-color: ${({ theme }) => theme.colors.text.success};
  border-radius: 50%;
  right: 8px;
  top: 9px;
`;
