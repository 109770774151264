import { styled } from '@pulse-web-ui/theme';

export const SetPeriodWrapper = styled.div`
  display: flex;
  gap: 18px;
  width: 100%;
`;

export const PeriodPickerWrapper = styled.div`
  width: calc(50% - 9px);
`;
