import { styled, typography } from '@pulse-web-ui/theme';

export const ResendComponent = styled.div`
  ${typography.regular14};
  color: ${({ theme }) => theme.colors?.text.secondary};
`;

export const ResendButton = styled.div`
  color: ${({ theme }) => theme.colors?.text.interactive};
  cursor: pointer;
`;
