import agentSalesListDictionary from '@widgets/agent-sales-list/i18n/ru/agent-sales-list.dic.json';
import agentSaleCardDictionary from '@widgets/agent-sale-card/i18n/ru/agent-sale-card.dic.json';
import agentSalesFilters from '@widgets/agent-sales-filters/i18n/ru/agent-sales-filters.dic.json';

export const agentSales = {
  AGENT_SALES_LIST: agentSalesListDictionary,
  AGENT_SALE_CARD: agentSaleCardDictionary,
  AGENT_SALES_FILTERS: agentSalesFilters,
};

export const agentSalesNamespaces = Object.keys(agentSales);
