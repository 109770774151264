import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import {
  NO_AGENT_CONTRACT,
  AnalyticEvent,
  useStores,
  CardWithIcon,
  UNABLE_TO_CONCLUDE_CONTRACT,
  PRODUCT_ICON_MAP,
  PRODUCT_LINK_MAP,
  ProductCode,
  ACCESS_SRG,
  WidgetHeaderWithSubText,
  addTestAttribute,
} from '@shared/index';
import { sendAnalyticEvent } from '@app/web-analytic';

import { List, ProductLink } from './product-list.styles';
import { ProductCodeWithoutNS } from '../../model';

export const ProductList = observer(() => {
  const {
    MainStore: {
      agentUserStore: { availableSign },
      productsStore: { products },
    },
  } = useStores();
  const { t } = useTranslation();

  const shouldConcludeContract = useMemo(
    () =>
      !!availableSign?.visibilityDocuments &&
      !!availableSign.isContractAvailable,
    [availableSign]
  );

  const isUnableToConcludeContract = useMemo(
    () =>
      availableSign?.visibilityDocuments === false &&
      availableSign.isContractAvailable === false,
    [availableSign]
  );

  const getLink = (productCode: ProductCodeWithoutNS) => {
    if (shouldConcludeContract) {
      return NO_AGENT_CONTRACT;
    }

    if (isUnableToConcludeContract) {
      return UNABLE_TO_CONCLUDE_CONTRACT;
    }

    if (
      productCode === ProductCode.MORTGAGE &&
      availableSign?.isSrgRegistered === false
    ) {
      return ACCESS_SRG;
    }

    return PRODUCT_LINK_MAP[productCode];
  };

  return (
    !!products?.length && (
      <>
        <WidgetHeaderWithSubText title={t('PRODUCTS:headers.newProduct')} />
        <List>
          {products?.map(({ productCode, productName, agentFeeDisplay }) => (
            <ProductLink
              onClick={() => {
                sendAnalyticEvent(AnalyticEvent.AGENT_NEWPRODUCT, {
                  isagent:
                    shouldConcludeContract || isUnableToConcludeContract
                      ? 'n'
                      : 'y',
                });
              }}
              key={productName}
              to={getLink(productCode)}
              target={
                productCode === ProductCode.MORTGAGE &&
                !!availableSign?.isSrgRegistered
                  ? '_blank'
                  : undefined
              }
              {...addTestAttribute(
                `products.${productCode}.createSubscription`
              )}
            >
              <CardWithIcon
                icon={PRODUCT_ICON_MAP[productCode]}
                title={productName}
                subtitle={agentFeeDisplay}
              />
            </ProductLink>
          ))}
        </List>
      </>
    )
  );
});
