import { Button } from '@pulse-web-ui/button';
import { styled, typography } from '@pulse-web-ui/theme';

export const TertiaryButton = styled(Button)`
  ${typography.regular14};
  padding: 6px 8px;
  color: ${({ theme }) => theme.colors.text.primary};
  line-height: 20px;
  background-color: #fff;
  border-radius: calc(${({ theme }) => theme.common.radius} * 2);
`;

export const ContentWrapper = styled.div<{
  iconPosition?: 'left' | 'right';
}>`
  display: flex;
  gap: 4px;
  flex-direction: ${({ iconPosition }) =>
    iconPosition === 'left' ? 'row' : 'row-reverse'};
  align-items: center;
`;
