import * as yup from 'yup';
import i18n from '@src/app/i18n/config';

export const agentContractAgreementsSchema = yup
  .object()
  .shape({
    contractConditionsAgreement: yup
      .bool()
      .required(i18n.t('COMMON:errors.required'))
      .oneOf([true], i18n.t('AGENT_CONTRACT_AGREEMENTS:errors.required')),
    personalDataManagement: yup
      .bool()
      .required(i18n.t('COMMON:errors.required'))
      .oneOf([true], i18n.t('AGENT_CONTRACT_AGREEMENTS:errors.required')),
  })
  .required();
