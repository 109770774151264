import {
  atLeastOneSpecialCharacter,
  atLeastOneLowerCaseCharacter,
  atLeastOneDigit,
  atLeastOneUpperCaseCharacter,
} from '@src/shared/regexps';

export const validatePassword = (password: string) => {
  const hasAtLeastOneSpecialCharacter =
    atLeastOneSpecialCharacter.test(password);
  const hasAtLeastOneLowerCaseCharacter =
    atLeastOneLowerCaseCharacter.test(password);
  const hasAtLeastOneDigit = atLeastOneDigit.test(password);
  const hasAtLeastOneUpperCaseCharacter =
    atLeastOneUpperCaseCharacter.test(password);

  return (
    hasAtLeastOneSpecialCharacter &&
    hasAtLeastOneLowerCaseCharacter &&
    hasAtLeastOneDigit &&
    hasAtLeastOneUpperCaseCharacter
  );
};
