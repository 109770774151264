import { Trans, useTranslation } from 'react-i18next';

import { PULSE_SUPPORT_EMAIL, TextLink } from '@shared/index';
import { Label } from '../agent-profile';

export const SupportLinkHint = () => {
  const { t } = useTranslation();

  return (
    <Label>
      <Trans
        values={{ href: PULSE_SUPPORT_EMAIL }}
        components={{ a: <TextLink /> }}
      >
        {t('AGENT_PROFILE:hints.support')}
      </Trans>
    </Label>
  );
};
