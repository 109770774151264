import {
  AgentEndpoints,
  CommonResponse,
  ConsentType,
  RequestMethods,
  useRequest,
} from '@src/shared';

export const useGetConsent = (consentType: ConsentType) => {
  const { res, isLoading, refetch, error, status, isFetching } = useRequest<
    unknown,
    BlobPart,
    CommonResponse
  >({
    params: {
      key: `getAgentConsent_${consentType}`,
      method: RequestMethods.GET,
      url: `${AgentEndpoints.AGENT_CONSENT}/${consentType}`,
      fetchManual: true,
    },
    config: { responseType: 'blob' },
  });

  return {
    res,
    error,
    isLoading,
    refetch,
    status,
    isFetching,
  };
};
