import * as yup from 'yup';
import i18n from '@src/app/i18n/config';
import { isBefore, startOfDay } from 'date-fns';

export const setPeriodSchema = yup
  .object()
  .shape({
    dateFrom: yup.date().required(),
    dateTo: yup
      .date()
      .required()
      .when('dateFrom', (dateFrom: string[], schema) => {
        if (!!dateFrom?.[0]) {
          return schema.test(
            'validEndDate',
            i18n.t('SET_PERIOD:errors.endDateLessThanStartDate'),
            (val) => {
              const from = startOfDay(new Date(dateFrom[0]));
              const to = startOfDay(val);

              return val ? !isBefore(to, from) : false;
            }
          );
        }

        return schema;
      }),
  })
  .required();
