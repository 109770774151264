import { styled } from '@pulse-web-ui/theme';

export const ExternalLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const TextLink = styled.a`
  color: ${({ theme }) => theme.colors.text.interactive};
  text-decoration: none;
`;
