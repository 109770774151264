import { makeAutoObservable, configure, runInAction } from 'mobx';
import { AgentUserStore, AgentActsStore } from '@entities/index';
import { AgentContractStore } from '@entities/agent-contract';
import { DictionariesStore } from '@entities/dictionaries';
import { AgentContractAgreementsStore } from '@features/agent-contract-agreements';
import { ProductsStore } from '@entities/product-list';

configure({
  enforceActions: 'always',
  computedRequiresReaction: true,
  reactionRequiresObservable: true,
  observableRequiresReaction: false,
  disableErrorBoundaries: true,
});

class Main {
  public agentUserStore: AgentUserStore;
  public agentContractStore: AgentContractStore;
  public dictionariesStore: DictionariesStore;
  public agentContractAgreementsStore: AgentContractAgreementsStore;
  public agentActsStore: AgentActsStore;
  public productsStore: ProductsStore;

  constructor() {
    this.agentUserStore = new AgentUserStore(this);
    this.agentContractStore = new AgentContractStore(this);
    this.dictionariesStore = new DictionariesStore(this);
    this.agentContractAgreementsStore = new AgentContractAgreementsStore(this);
    this.agentActsStore = new AgentActsStore(this);
    this.productsStore = new ProductsStore(this);
    makeAutoObservable(this);
  }

  resetStore() {
    runInAction(() => {
      this.agentUserStore.reset();
      this.agentContractStore.reset();
      this.dictionariesStore.reset();
      this.agentContractAgreementsStore.reset();
      this.agentActsStore.reset();
      this.productsStore.reset();
    });
  }
}

export { Main };
