export enum Product {
  HOUSE = 'house',
  APARTMENT = 'apartment',
  NS = 'ns',
  PETS = 'pets',
  SPORT = 'sport-ns',
  MITE = 'mite',
  TRAVEL = 'travel',
}

export enum ProductCode {
  HOUSE = '08.16',
  APARTMENT = '08.15',
  NS = '02.09',
  PETS = '00.04',
  SPORT = '02.13',
  MITE = '00.09',
  TRAVEL = '03.08',
  MORTGAGE = '08.28',
}
