import { styled, typography } from '@pulse-web-ui/theme';
import { WidgetHeader } from '@shared/index';

export const AgentProfileContainer = styled.div`
  max-width: 768px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledWidgetHeader = styled(WidgetHeader)`
  margin-bottom: 12px;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Label = styled.h4`
  ${typography.regular12};
  color: ${({ theme }) => theme.colors.text.secondary};
  margin: 0;
`;

export const Value = styled.p`
  ${typography.regular16};
  margin: 0;
  line-height: 24px;
  flex: 1;
`;
