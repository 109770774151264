import { styled, typography } from '@pulse-web-ui/theme';

export const ListItem = styled.li`
  ${typography.regular14}
  color: ${({ theme }) => theme.colors?.text.secondary};
  position: relative;
  box-sizing: border-box;
  padding-left: 16px;

  &::before {
    content: '';
    background: ${({ theme }) => theme.colors?.text.secondary};
    width: 4px;
    height: 4px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 6px;
  }
`;
