import { memo, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  FormControlContainer,
  WidgetSubHeaderText,
  InputController,
} from '@shared/index';

import { LoginFormProps } from '../model/types/login-form.types';

export const Login = memo(() => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    clearErrors,
  } = useFormContext<LoginFormProps>();

  const handleInputChange = useCallback(() => {
    if (Object.values(errors)) {
      clearErrors();
    }
  }, [clearErrors, errors]);

  return (
    <div>
      <WidgetSubHeaderText>
        {t('LOGIN:headers.enterToPersonalAccount')}
      </WidgetSubHeaderText>
      <FormControlContainer>
        <InputController<LoginFormProps>
          control={control}
          name="phoneNumber"
          label={t('COMMON:labels.phone') || ''}
          id="phoneNumber"
          onChange={handleInputChange}
          type="tel"
          testId="login.phone"
        />
      </FormControlContainer>
      <FormControlContainer margin={'0px 0px 16px 0px'}>
        <InputController<LoginFormProps>
          control={control}
          name="password"
          label={t('LOGIN:labels.password') || ''}
          id="password"
          onChange={handleInputChange}
          type="password"
          testId="login.password"
        />
      </FormControlContainer>
    </div>
  );
});
