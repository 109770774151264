import { useRequest, RequestMethods } from '@src/shared';
import { RequestDeps } from '@src/shared/types/request.types';

import { PasswordRequestParams, PasswordResponse } from './use-password.types';
import { UserEndpoints } from '@src/entities';
import { CommonResponse } from '@src/shared/types';

type useRefreshCodeProps = {
  phone: string | undefined;
  request: PasswordRequestParams;
  deps?: RequestDeps;
};

export const usePassword = ({ phone, request, deps }: useRefreshCodeProps) => {
  const { res, isLoading, refetch, error, status, isFetching, isRefetching } =
    useRequest<PasswordRequestParams, PasswordResponse, CommonResponse>({
      params: {
        key: 'password',
        method: RequestMethods.PUT,
        url: `${UserEndpoints.USERS}/${phone}/password/otp`,
        request,
        fetchManual: true,
        deps,
      },
    });

  return {
    res,
    error,
    isLoading,
    refetch,
    status,
    isFetching,
    isRefetching,
  };
};
