import { WidgetContainer } from '@pulse-web-ui/containers';
import { styled, typography } from '@pulse-web-ui/theme';

export const Container = styled(WidgetContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BalanceAmount = styled.h2`
  ${typography.medium24}
  font-weight: 600;
  margin: 0;
  display: block;
`;

export const TitleHint = styled.h4`
  ${typography.regular14}
  margin: 0;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text.secondary};
`;
