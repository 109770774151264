import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TooltipDirection, TriggerDirection } from '@pulse-web-ui/tooltip';
import { HelperText } from '@pulse-web-ui/helper-text';
import { AutocompleteSelect } from '@pulse-web-ui/autocomplete-select';

import {
  AgentEmploymentType,
  AgentType,
  DatepickerController,
  FormControlContainer,
  InputController,
  SelectController,
  useStores,
  TextWithTooltip,
} from '@shared/index';

import type { AgentEmploymentDetailsFormParams } from '../../model';
import { OGRNIP_MASK } from '../../model/constants';

import { useGetAgentEmploymentDetailsBranchOptions } from '../../lib';

interface AgentEmploymentDetailsProps {
  minDateIpRegistration: Date;
}

export const AgentEmploymentDetails = observer(
  ({ minDateIpRegistration }: AgentEmploymentDetailsProps) => {
    const { t } = useTranslation();
    const {
      MainStore: {
        agentContractStore: { profile },
      },
    } = useStores();

    const agentOptions = [
      {
        label: t('AGENT_EMPLOYMENT_DETAILS:employmentTypeOptions.rgs'),
        value: AgentEmploymentType.RGS,
      },
      {
        label: t('AGENT_EMPLOYMENT_DETAILS:employmentTypeOptions.external'),
        value: AgentEmploymentType.EXTERNAL,
      },
    ];

    const agentTypeOptions = [
      {
        label: t('AGENT_EMPLOYMENT_DETAILS:typeOptions.IP'),
        value: AgentType.IP,
      },
      {
        label: t('AGENT_EMPLOYMENT_DETAILS:typeOptions.FL'),
        value: AgentType.FL,
      },
    ];

    const {
      control,
      formState: { errors },
      clearErrors,
      setValue,
      watch,
    } = useFormContext<AgentEmploymentDetailsFormParams>();

    const { branchOptions, defaultBranchCode } =
      useGetAgentEmploymentDetailsBranchOptions();

    useEffect(() => {
      if (!!branchOptions.length && !!defaultBranchCode) {
        setValue('branchCode', defaultBranchCode);
      }
    }, [defaultBranchCode, branchOptions.length]);

    const isImported = profile?.isImported;
    const isIP = watch('agentType') === AgentType.IP;

    const maxDateIpRegistration = new Date();

    const isBranchCodeDisabled = isImported && !!defaultBranchCode;

    return (
      <>
        <FormControlContainer>
          <Controller
            control={control}
            name="branchCode"
            render={({ field: { onChange, value }, fieldState }) => (
              <HelperText
                status={!!fieldState.error ? 'error' : 'default'}
                message={
                  errors.branchCode?.message ||
                  (!isImported &&
                    !fieldState.isDirty &&
                    t('AGENT_EMPLOYMENT_DETAILS:hints.branchCode'))
                }
                testId="agentEmploymentDetails.branchCodeHint"
              >
                <AutocompleteSelect
                  onChange={(value) => {
                    if (errors) {
                      clearErrors();
                    }
                    onChange(value);
                  }}
                  value={value || ''}
                  options={branchOptions}
                  label={t('AGENT_EMPLOYMENT_DETAILS:labels.branchCode') || ''}
                  status={fieldState.error && 'error'}
                  isLoading={branchOptions?.length === 0}
                  testId="agentEmploymentDetails.branchCode"
                  disabled={isBranchCodeDisabled}
                />
              </HelperText>
            )}
          />
        </FormControlContainer>
        <FormControlContainer>
          <SelectController
            options={agentOptions}
            control={control}
            name="employmentType"
            label={t('AGENT_EMPLOYMENT_DETAILS:labels.employmentType')}
            testId="agentEmploymentDetails.employmentType"
            disabled={isImported}
          />
          <TextWithTooltip
            text={t('AGENT_EMPLOYMENT_DETAILS:hints.employmentTypeTitle')}
            tooltipText={t('AGENT_EMPLOYMENT_DETAILS:hints.employmentType')}
            direction={TooltipDirection.DOWN}
            trigger={TriggerDirection.CLICK}
          />
        </FormControlContainer>
        <FormControlContainer>
          <SelectController
            options={agentTypeOptions}
            control={control}
            name="agentType"
            label={t('AGENT_EMPLOYMENT_DETAILS:labels.agentType')}
            testId="agentEmploymentDetails.agentType"
            disabled={isImported}
          />
        </FormControlContainer>
        {isIP && (
          <>
            <FormControlContainer>
              <InputController
                control={control}
                name="ogrnIp"
                id="ogrnIp"
                label={t('AGENT_EMPLOYMENT_DETAILS:labels.ogrnIp')}
                placeholder="000000000000000"
                mask={OGRNIP_MASK}
                testId="agentEmploymentDetails.ogrnIp"
              />
            </FormControlContainer>
            <FormControlContainer>
              <DatepickerController
                control={control}
                name="dateIpRegistration"
                label={t('AGENT_EMPLOYMENT_DETAILS:labels.dateIpRegistration')}
                minDate={minDateIpRegistration}
                maxDate={maxDateIpRegistration}
                testId="agentEmploymentDetails.dateIpRegistration"
                showMonthDropdown
                showYearDropdown
                showInput
              />
            </FormControlContainer>
          </>
        )}
      </>
    );
  }
);
