import { makeAutoObservable } from 'mobx';

import { Main } from '@app/store';

export class AgentActsStore {
  private mainStore: Main;
  actsIdToSign?: string[];

  constructor(mainStore: Main) {
    this.mainStore = mainStore;
    makeAutoObservable(this);
  }

  setActsIdToSign = (ids: string[]) => {
    this.actsIdToSign = ids;
  };

  reset() {
    this.actsIdToSign = undefined;
  }
}
