import { useCallback, useEffect, useRef } from 'react';
import { AxiosError } from 'axios';
import { useQueryClient } from '@tanstack/react-query';

import { ERROR } from '../constants';

interface Args<T> {
  res: BlobPart | undefined;
  isFetching: boolean;
  error: AxiosError<T> | null;
  queryKeyToClearCache?: string;
}

export const useOpenFile = <T>({
  res,
  isFetching,
  error,
  queryKeyToClearCache,
}: Args<T>) => {
  const queryClient = useQueryClient();
  const windowRef = useRef<Window | null>(null);

  useEffect(() => {
    if (!isFetching && res) {
      const blob = new Blob([res], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      if (windowRef.current) {
        windowRef.current.location.href = url;
      }

      queryClient.removeQueries({ queryKey: [queryKeyToClearCache] });
    }
  }, [res, isFetching]);

  useEffect(() => {
    if (error) {
      if (windowRef.current) {
        windowRef.current.location.href = ERROR;
      }
      queryClient.removeQueries({ queryKey: [queryKeyToClearCache] });
    }
  }, [error]);

  return useCallback(() => {
    windowRef.current = window.open('', '_blank');
  }, []);
};
