import CityIcon from '@assets/images/city-emoji.png';
import HomeIcon from '@assets/images/home-emoji.png';
import PetIcon from '@assets/images/pet-emoji.png';
import SportIcon from '@assets/images/sport-emoji.png';
import SpiderIcon from '@assets/images/spider-emoji.png';
import PalmIcon from '@assets/images/palm-emoji.png';
import DoorIcon from '@assets/images/door-emoji.png';
import { TFunction } from 'i18next';

import {
  CREATE_SUBSCRIPTION,
  LANDING_PAGE_APARTMENT,
  LANDING_PAGE_HOUSE,
  LANDING_PAGE_MITE,
  LANDING_PAGE_PETS,
  LANDING_PAGE_SPORT,
  LANDING_PAGE_TRAVEL,
  SRG_LINK,
} from '../constants';
import { Product, ProductCode } from '../types';

type ProductCodeWithoutNSAndMortgage = Exclude<ProductCode, '08.28' | '02.09'>;

export const PRODUCT_ICON_MAP = {
  [ProductCode.APARTMENT]: CityIcon,
  [ProductCode.HOUSE]: HomeIcon,
  [ProductCode.MITE]: SpiderIcon,
  [ProductCode.PETS]: PetIcon,
  [ProductCode.SPORT]: SportIcon,
  [ProductCode.TRAVEL]: PalmIcon,
  [ProductCode.MORTGAGE]: DoorIcon,
};

export const PRODUCT_LINK_MAP = {
  [ProductCode.APARTMENT]: `${CREATE_SUBSCRIPTION}?product=${Product.APARTMENT}`,
  [ProductCode.HOUSE]: `${CREATE_SUBSCRIPTION}?product=${Product.HOUSE}`,
  [ProductCode.MITE]: `${CREATE_SUBSCRIPTION}?product=${Product.MITE}`,
  [ProductCode.PETS]: `${CREATE_SUBSCRIPTION}?product=${Product.PETS}`,
  [ProductCode.SPORT]: `${CREATE_SUBSCRIPTION}?product=${Product.SPORT}`,
  [ProductCode.TRAVEL]: `${CREATE_SUBSCRIPTION}?product=${Product.TRAVEL}`,
  [ProductCode.MORTGAGE]: SRG_LINK,
};

export const PRODUCT_LANDING_LINK_MAP: Record<
  ProductCodeWithoutNSAndMortgage,
  string
> = {
  [ProductCode.APARTMENT]: LANDING_PAGE_APARTMENT,
  [ProductCode.HOUSE]: LANDING_PAGE_HOUSE,
  [ProductCode.MITE]: LANDING_PAGE_MITE,
  [ProductCode.PETS]: LANDING_PAGE_PETS,
  [ProductCode.SPORT]: LANDING_PAGE_SPORT,
  [ProductCode.TRAVEL]: LANDING_PAGE_TRAVEL,
};

export const getProducts = (
  t: TFunction
): {
  productCode: ProductCodeWithoutNSAndMortgage;
  productName: string;
  description: string;
}[] => [
  {
    productCode: ProductCode.APARTMENT,
    productName: t('PRODUCTS:products.flat'),
    description: t('PRODUCTS:descriptions:flat'),
  },
  {
    productCode: ProductCode.HOUSE,
    productName: t('PRODUCTS:products.house'),
    description: t('PRODUCTS:descriptions:house'),
  },
  {
    productCode: ProductCode.PETS,
    productName: t('PRODUCTS:products.pet'),
    description: t('PRODUCTS:descriptions:pet'),
  },
  {
    productCode: ProductCode.SPORT,
    productName: t('PRODUCTS:products.sport'),
    description: t('PRODUCTS:descriptions:sport'),
  },
  {
    productCode: ProductCode.MITE,
    productName: t('PRODUCTS:products.spider'),
    description: t('PRODUCTS:descriptions:mite'),
  },
  {
    productCode: ProductCode.TRAVEL,
    productName: t('PRODUCTS:products.travel'),
    description: t('PRODUCTS:descriptions:travel'),
  },
];
