import { styled } from '@pulse-web-ui/theme';

export const Container = styled.div`
  position: relative;
`;

export const MenuList = styled.ul`
  position: absolute;
  top: 32px;
  right: 0;
  width: 198px;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: ${({ theme }) => theme.colors.surface.primary};
  border-radius: calc(${({ theme }) => theme.common.radius});
  ${({ theme }) => theme.common['box-shadow']};
`;

export const MenuItem = styled.li`
  cursor: pointer;
`;

export const MenuTrigger = styled.div`
  width: 24px;
  height: 24px;
  transform: rotate(90deg);
  cursor: pointer;
`;
