/* eslint-disable indent */
import { css, mixins, styled, typography } from '@pulse-web-ui/theme';
import { LabelProps, SelectProps } from './select.types';

export const SelectCommonStyles = css<
  SelectProps & {
    isError?: boolean;
  }
>`
  ${typography.regular18};
  box-sizing: border-box;
  width: 100%;
  height: 56px;
  border: 1.5px solid ${(props) => props.theme.colors.neutral[10]};
  border-radius: ${(props) => props.theme.common.radius};
  display: block;
  color: ${(props) => props.theme.colors.text.primary};
  outline: none;
  background: ${(props) =>
    props.theme.common?.outlined
      ? 'transparent'
      : props.theme.colors.neutral[10]};
  transition: 300ms ease all;

  &:hover {
    background: ${(props) =>
      !props.theme.common?.outlined && props.theme.colors.neutral[20]};
    border: 1.5px solid ${(props) => props.theme.colors.neutral[20]};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background: ${(props) =>
        props.theme.common?.outlined
          ? 'transparent'
          : props.theme.colors.neutral[30]};
      opacity: 1;
      pointer-events: none;
      color: ${(props) =>
        props.theme.common?.outlined
          ? props.theme.colors.text.tertiary
          : props.theme.colors.text.secondary};
      border-color: ${(props) =>
        props.theme.common?.outlined
          ? props.theme.colors.neutral[10]
          : props.theme.colors.neutral[30]};
      -webkit-text-fill-color: ${(props) => props.theme.colors.text.secondary};
    `}

  ${({ isError, disabled }) =>
    isError &&
    css`
      border: 1.5px solid ${(props) => props.theme.colors.accent.error.normal};
      border-color: ${(props) => props.theme.colors.accent.error.normal};
      background-color: ${(props) => props.theme.colors.accent.error.faded};
      color: ${(props) => disabled && props.theme.colors.text.error};

      &:hover,
      &:focus {
        color: ${(props) => props.theme.colors.text.primary};
      }
    `}
`;

export const StyledSelect = styled.div<
  SelectProps & {
    isError?: boolean;
  }
>`
  ${SelectCommonStyles};
  ${typography.regular18};
  width: 100%;
  position: relative;
  color: ${({ theme }) => theme.colors.text.primary};
  padding: 24px 16px 16px;
`;

export const SelectPopover = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  top: 56px;
  z-index: 20;
`;

export const SelectList = styled.ul`
  ${({ theme }) => theme.common['box-shadow']};
  list-style: none;
  padding: 0;
  width: 100%;
  background: ${({ theme }) => theme.colors.neutral[0]};
  border-radius: ${({ theme }) => theme.common.radius};
  box-sizing: border-box;
  margin: 0;
  overflow: auto;
  max-height: 320px;
`;

export const SelectOption = styled.li`
  cursor: pointer;
  padding: 20px 16px;
  display: flex;
  justify-content: space-between;
  align-content: center;
`;

export const SelectArrow = styled.div<{
  disabled?: boolean;
}>`
  ${mixins.flex({ justifyContent: 'center', alignItems: 'center' })};
  width: 62px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      path {
        fill: ${theme.colors.neutral};
      }
    `}
`;

export const shrinkLabelStyles = css<{
  isError?: boolean;
}>`
  top: 7px;
  font-size: 12px;
  line-height: 18px;
`;

export const Label = styled.label<
  LabelProps & {
    isError?: boolean;
  }
>`
  ${typography.regular16};
  color: ${({ theme }) => theme.colors.text.secondary};
  word-break: break-word;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  padding-right: 16px;
  left: 16px;
  top: 19px;
  transition: 300ms ease all;
  ${({ value }) => {
    return Boolean(value?.length) && shrinkLabelStyles;
  }};
  color: ${({ disabled, theme }) => {
    const disabledColor = theme.common?.outlined
      ? theme.colors.text.tertiary
      : theme.colors.text.secondary;
    return disabled && disabledColor;
  }};
  color: ${({ isError, theme }) => isError && theme.colors.text.error};
`;
