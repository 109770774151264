import { styled } from '@pulse-web-ui/theme';

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  max-width: 100%;
  min-height: 100vh;
  padding: 88px 0 40px;
  background: ${({ theme }) => theme.colors.surface.background};
  box-sizing: border-box;
`;
