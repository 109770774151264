import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { format } from 'date-fns';
import { useErrorBoundary } from 'react-error-boundary';
import { useFormContext } from 'react-hook-form';
import { HelperText } from '@pulse-web-ui/helper-text';

import type { AgentEmploymentDetailsFormParams } from '@entities/agent-employment-details';
import {
  SubmitButton,
  useStores,
  addTestAttribute,
  DateFormat,
  BoundaryErrorType,
} from '@shared/index';
import type {
  PersonalDocuments,
  UpdateProfileRequestParams,
} from '@entities/agent-contract';
import { useUpdateProfile } from '@entities/agent-contract';

interface SubmitAgentEmploymentDetailsProps {
  onNextStep: (isContractReady?: boolean) => void;
}

export const SubmitAgentEmploymentDetails = observer(
  ({ onNextStep }: SubmitAgentEmploymentDetailsProps) => {
    const { t } = useTranslation();
    const { showBoundary } = useErrorBoundary();

    const {
      MainStore: {
        agentContractStore: {
          profile,
          setEmploymentDetails,
          setPersonalDocuments,
          updateProfile,
        },
        dictionariesStore: { isAgentBranchDictionaryLoaded },
      },
    } = useStores();

    const {
      handleSubmit,
      formState: { errors },
    } = useFormContext<AgentEmploymentDetailsFormParams>();

    const [requestParams, setRequestParams] =
      useState<UpdateProfileRequestParams>({
        isComplete: true,
        profile: {
          ...profile,
          employmentDetails: undefined,
        },
      });

    const { res, refetch, error, isFetching } = useUpdateProfile(
      {
        ...requestParams,
      },
      [requestParams]
    );

    useEffect(() => {
      if (
        !!requestParams?.profile?.employmentDetails?.branchCode?.length &&
        !!requestParams?.profile?.employmentDetails?.employmentType?.length &&
        !!requestParams?.profile?.agentType?.length &&
        // If agentType is IP then ogrnIp and dateIpRegistration fields are required
        (requestParams?.profile?.agentType === 'IP'
          ? !!requestParams?.profile?.personalDocument?.ogrnIp?.length &&
            !!requestParams?.profile?.personalDocument?.dateIpRegistration
              ?.length
          : true)
      ) {
        refetch();
      }
    }, [requestParams]);

    useEffect(() => {
      if (!isFetching && res) {
        if (requestParams?.profile?.employmentDetails) {
          setEmploymentDetails(requestParams?.profile?.employmentDetails);
        }

        if (requestParams.profile.agentType) {
          updateProfile({ agentType: requestParams.profile.agentType });
        }

        if (requestParams?.profile?.personalDocument) {
          setPersonalDocuments(requestParams?.profile?.personalDocument);
        }

        onNextStep?.();
      }
    }, [isFetching, res]);

    useEffect(() => {
      if (error) {
        showBoundary(BoundaryErrorType.SomethingWentWrong);
      }
    }, [error]);

    const submitPage = handleSubmit((values) => {
      const {
        branchCode,
        employmentType,
        agentType,
        ogrnIp: ogrnIpValue,
        dateIpRegistration: dateIpRegistrationValue,
      } = values;

      const ipTaxationService = profile?.employmentDetails?.ipTaxationService;
      const isIp = agentType === 'IP';

      const dateIpRegistration =
        isIp && dateIpRegistrationValue
          ? format(new Date(dateIpRegistrationValue), DateFormat.YYYY_MM_DD)
          : undefined;
      const ogrnIp = isIp && ogrnIpValue ? ogrnIpValue : undefined;

      setRequestParams((prevParams) => ({
        ...prevParams,
        profile: {
          ...prevParams.profile,
          agentType,
          employmentDetails: {
            branchCode,
            employmentType,
            ipTaxationService,
          },
          personalDocument: {
            ...(prevParams.profile.personalDocument as PersonalDocuments),
            ogrnIp,
            dateIpRegistration,
          },
        },
      }));
    });

    return (
      <HelperText
        status="error"
        message={
          errors?.root?.serverError.type &&
          t('COMMON:errors.duplicatedData', {
            code: errors.root.serverError.type,
          })
        }
      >
        <SubmitButton
          variant="primary"
          label={t('AGENT_EMPLOYMENT_DETAILS:buttons.createContract') || ''}
          onClick={submitPage}
          disabled={
            !!Object.keys(errors).length || !isAgentBranchDictionaryLoaded
          }
          isLoading={isFetching}
          {...addTestAttribute('agentEmploymentDetails.submitButton')}
        />
      </HelperText>
    );
  }
);
