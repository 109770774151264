/*
Алгоритм проверки счёта с помощью БИКа банка:
1. Изменение счета с учетом БИКа(см ниже).
2. Вычисляется контрольная сумма со следующими весовыми коэффициентами: (7,1,3,7,1,3,7,1,3,7,1,3,7,1,3,7,1,3,7,1,3,7,1)
3. Вычисляется контрольное число как остаток от деления контрольной суммы на 10
4. Контрольное число сравнивается с нулём. В случае их равенства расчётного счёт считается правильным.
*/

//функция проверки правильности указания банковского счёта
export const checkBankAccount = (account: string) => {
  //весовые коэффициенты
  const v = [
    7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1,
  ];

  //вычисляем контрольную сумму
  const sum = v.reduce(
    (acc, cur, i) => acc + ((Number(account.charAt(i)) * cur) % 10),
    0
  );

  //сравниваем остаток от деления контрольной суммы на 10 с нулём
  return sum % 10 == 0;
};

/*
Проверка правильности указания корреспондентского счёта:
1. Для проверки контрольной суммы перед корреспондентским счётом добавляются "0" и два знака БИКа банка, начиная с пятого знака.
*/
export const checkKS = (account: string, bic: string) =>
  checkBankAccount('0' + bic.substring(4, 6) + account);

/*
Проверка правильности указания расчётного счёта:
1. Для проверки контрольной суммы перед расчётным счётом добавляются три последние цифры БИКа банка.
*/
export const checkRS = (account: string, bic: string) =>
  checkBankAccount(bic.substring(6, 9) + account);
