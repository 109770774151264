import { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext, handleTokensEpxUpdate } from '@contexts/user-context';

import { axiosInstance } from '../utils';
import { AuthEndpoints } from '../constants';

export const useAxiosInterceptors = () => {
  const navigate = useNavigate();
  const { handleLogout } = useContext(UserContext);

  const handleResponse = useCallback((response: AxiosResponse) => {
    const {
      headers: {
        'access-token-expires': accessExp,
        'refresh-token-expires': refreshExp,
      },
    } = response;
    handleTokensEpxUpdate(accessExp, refreshExp);

    return response;
  }, []);

  const handleError = useCallback(
    async (err: AxiosError) => {
      const status = err?.response?.status;
      const url = err?.config?.url;

      if ((status === 401 || status === 403) && url !== AuthEndpoints.LOGIN) {
        handleLogout();
      }

      return Promise.reject(err);
    },
    [navigate]
  );

  const interceptor = useMemo(() => {
    return axiosInstance.interceptors.response.use(handleResponse, handleError);
  }, [handleResponse, handleError]);

  useEffect(() => {
    return () => {
      axiosInstance.interceptors.response.eject(interceptor);
    };
  }, [interceptor]);
};
