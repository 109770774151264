import amplitude from 'amplitude-js';
import ym from 'react-yandex-metrika';

export const sendUserIdToAnalytics = (id: string) => {
  amplitude.getInstance().setUserId(id);
  ym('userParams', {
    UserID: id,
  });
  ym('setUserID', id);
};
