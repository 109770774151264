import type { LoginRequestParams, LoginResponse } from '@shared/types';
import { useRequest, RequestMethods, AuthEndpoints } from '@shared/index';

export const useLogin = ({ username, password }: LoginRequestParams) => {
  const { res, isLoading, refetch, error, status, isFetching } = useRequest<
    LoginRequestParams,
    LoginResponse,
    any
  >({
    params: {
      key: 'login',
      method: RequestMethods.POST,
      url: AuthEndpoints.LOGIN,
      request: {
        username,
        password,
      },
      fetchManual: true,
    },
  });

  return {
    res,
    error,
    isLoading,
    refetch,
    status,
    isFetching,
  };
};
