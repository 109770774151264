import { WidgetContainer } from '@pulse-web-ui/containers';
import { styled, typography } from '@pulse-web-ui/theme';

export const Container = styled(WidgetContainer)`
  display: flex;
  gap: 6px;
  padding: 16px;
`;

export const TextContainer = styled.p`
  ${typography.regular12}
  line-height: 20px;
  margin: 0;
  flex: 1;
`;
