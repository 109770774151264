import type { CommonResponse } from '@shared/types';
import { useRequest, RequestMethods, AuthEndpoints } from '@shared/index';

export const useLogout = () => {
  const { res, isLoading, refetch, error, status, isFetching } = useRequest<
    never,
    never,
    CommonResponse
  >({
    params: {
      key: 'logout',
      method: RequestMethods.GET,
      url: AuthEndpoints.LOGOUT,
      fetchManual: true,
    },
  });

  return {
    res,
    error,
    isLoading,
    refetch,
    status,
    isFetching,
  };
};
