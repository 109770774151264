import { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { LoginRequestParams } from '@shared/types';
import { MAIN, useStores } from '@shared/index';

import {
  LocalStorageUserInfoKeys,
  getCurrentUserInfo,
  isLoggedIn,
  putAuthenticationInfoInLocalStorage,
  removeUserInfoFromLocalStorage,
} from './user-context-utils';
import { UserContext } from './user-context';
import {
  injectAutoLogoutActions,
  setUserSessionAutoLogout,
} from './user-auto-logout';
import { useLogin } from './use-login';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { sendUserIdToAnalytics } from '@src/app/web-analytic';

interface Props {
  children: JSX.Element;
}

export const UserAuthProvider = ({ children }: Props) => {
  const [username, setUsername] = useState<string | undefined | null>();
  const [password, setPassword] = useState<string | undefined | null>();
  const [loginError, setLoginError] = useState<AxiosError<{
    code?: string;
  }> | null>();
  const isAuthenticated = isLoggedIn();
  const queryClient = useQueryClient();
  const { MainStore } = useStores();
  const [redirectTo, setRedirectTo] = useState<string>();
  const navigate = useNavigate();

  const getUserName = () => {
    return username || localStorage.getItem(LocalStorageUserInfoKeys.name);
  };

  const { res, refetch, error, isFetching } = useLogin({
    username,
    password,
  });

  const handleLogout = useCallback(() => {
    removeUserInfoFromLocalStorage();
    setUsername(null);
    setPassword(null);
    setLoginError(null);
    MainStore.resetStore();
    queryClient.removeQueries();
  }, []);

  const handleLogin = useCallback((params: LoginRequestParams) => {
    setUsername(params.username);
    setPassword(params.password);
    sendUserIdToAnalytics(params.username || '');
    setRedirectTo(params.redirectTo);
  }, []);

  useEffect(() => {
    if (!!username && !!password) {
      refetch();
    }
  }, [username, password]);

  useEffect(() => {
    if (!isFetching && res) {
      navigate(redirectTo ? redirectTo : MAIN);
      putAuthenticationInfoInLocalStorage(res);
      setUserSessionAutoLogout();
      const { name } = getCurrentUserInfo();
      setUsername(name);
      setRedirectTo(undefined);
    }
  }, [isFetching, res]);

  useEffect(() => {
    if (error) {
      setLoginError(error);
      console.error(error);
    }
  }, [error]);

  useEffect(() => {
    injectAutoLogoutActions(handleLogout);
  }, [handleLogout]);

  const context = useMemo(
    () => ({
      handleLogin,
      handleLogout,
      isAuthenticated,
      loginError,
      username,
      getUserName,
    }),
    [
      handleLogin,
      handleLogout,
      isAuthenticated,
      loginError,
      username,
      getUserName,
    ]
  );

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
};
