import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { ReactNode } from 'react';

import { addTestAttribute } from '@shared/index';
import { StyledCheckbox } from './checkbox-controller.styles';

type Props<T extends FieldValues> = UseControllerProps<T> & {
  label: string | ReactNode;
  id?: string;
  onChange?: (val: boolean) => void;
  testId?: string;
};

export const CheckboxController = <T extends FieldValues>({
  label,
  onChange,
  testId = 'checkbox',
  id,
  ...controllerProps
}: Props<T>) => {
  const { field, fieldState } = useController(controllerProps);

  return (
    <StyledCheckbox
      checked={field.value}
      onChange={(val) => {
        field.onChange(val);
        onChange && onChange(field.value);
      }}
      id={id}
      label={label}
      status={!!fieldState.error ? 'error' : undefined}
      message={fieldState.error?.message}
      {...addTestAttribute(testId)}
    />
  );
};
