import { lazy, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTheme } from '@pulse-web-ui/theme';

import { Container, MenuItem, MenuList, MenuTrigger } from './card-menu.styles';

const KebabIcon = lazy(() => import('@pulse-web-ui/icons/lib/lazy/more'));

interface MenuItem {
  label: string;
  onClick: () => void;
  isVisible: boolean;
}

interface CardMenuProps {
  menuItems: MenuItem[];
  testId?: string;
}

export const CardMenu = memo(
  ({ menuItems, testId = 'card-menu' }: CardMenuProps) => {
    const theme = useTheme();
    const menuEl = useRef<HTMLUListElement>(null);
    const menuTriggerEl = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleMenuOpen = () => {
      setIsOpen(true);
    };

    const handleCloseMenu = useCallback((e: MouseEvent) => {
      const isInsideMenu =
        !(e.target instanceof Element) ||
        menuEl.current?.contains(e.target) ||
        menuTriggerEl.current?.contains(e.target);

      if (isInsideMenu) return;

      setIsOpen(false);
    }, []);

    useEffect(() => {
      document.addEventListener('click', handleCloseMenu);

      return () => document.removeEventListener('click', handleCloseMenu);
    }, [handleCloseMenu]);

    return (
      <Container>
        <MenuTrigger
          onClick={handleMenuOpen}
          data-testid={`${testId}-icon`}
          ref={menuTriggerEl}
        >
          <KebabIcon color={theme.colors.icon.quaternary} />
        </MenuTrigger>
        {isOpen && (
          <MenuList ref={menuEl}>
            {menuItems.map(
              ({ label, onClick, isVisible }, index) =>
                isVisible && (
                  <MenuItem
                    key={label}
                    onClick={onClick}
                    data-testid={`${testId}-${index}`}
                  >
                    {label}
                  </MenuItem>
                )
            )}
          </MenuList>
        )}
      </Container>
    );
  }
);
