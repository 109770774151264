import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { useErrorBoundary } from 'react-error-boundary';

import { HelperText } from '@pulse-web-ui/helper-text';

import { SubmitButton } from '@src/shared/ui';

import {
  useStores,
  BoundaryErrorType,
  AnalyticEvent,
  removeSpaces,
  getMismatches,
  FlkCode,
  addTestAttribute,
} from '@shared/index';

import {
  UpdateProfileRequestParams,
  useUpdateProfile,
} from '@src/entities/agent-contract';
import { AgentBankDetailsFormParams } from '@src/entities/agent-bank-details/model';
import { sendAnalyticEvent } from '@src/app/web-analytic';

interface SubmitBankDetailsProps {
  onNextStep: (isContractReady?: boolean) => void;
  isLoading?: boolean;
}

export const SubmitBankDetails = observer(
  ({ onNextStep, isLoading }: SubmitBankDetailsProps) => {
    const { t } = useTranslation();
    const { showBoundary } = useErrorBoundary();
    const {
      MainStore: {
        agentContractStore: { setBankDetails, profile },
      },
    } = useStores();
    const {
      handleSubmit,
      formState: { errors },
      setError,
    } = useFormContext<AgentBankDetailsFormParams>();
    const [requestParams, setRequestParams] =
      useState<UpdateProfileRequestParams>({
        isComplete: false,
        profile: {
          ...profile,
          bankDetails: undefined,
        },
      });

    const { res, refetch, error, isFetching } = useUpdateProfile(
      {
        ...requestParams,
      },
      [requestParams]
    );

    useEffect(() => {
      if (
        !!requestParams?.profile?.bankDetails?.bic?.length &&
        !!requestParams?.profile?.bankDetails?.nameBank?.length &&
        !!requestParams?.profile?.bankDetails?.corAccount?.length &&
        !!requestParams?.profile?.bankDetails?.bankAccount?.length
      ) {
        refetch();
      }
    }, [requestParams]);

    useEffect(() => {
      if (!isFetching && res) {
        if (!!requestParams?.profile?.bankDetails) {
          setBankDetails(requestParams?.profile?.bankDetails);
          sendAnalyticEvent(AnalyticEvent.AGENT_AGREEMENT_TOSTEP_FORMS);
          onNextStep && onNextStep(res.isContractReady);
        }
      }
    }, [isFetching, res]);

    useEffect(() => {
      if (error) {
        const mismatches = getMismatches(error);

        if (!!mismatches) {
          mismatches.forEach(({ code }) => {
            if (code === FlkCode.AGT_01_0011) {
              setError('root.serverError', {
                type: code,
              });
            } else {
              showBoundary(BoundaryErrorType.SomethingWentWrong);
            }
          });
        } else {
          showBoundary(BoundaryErrorType.SomethingWentWrong);
        }
      }
    }, [error]);

    const submitPage = handleSubmit((values) => {
      const { bic, nameBank, bankAccount, corAccount } = values;

      setRequestParams((prevParams) => ({
        ...prevParams,
        profile: {
          ...prevParams.profile,
          bankDetails: {
            bic,
            nameBank,
            bankAccount: removeSpaces(bankAccount),
            corAccount: removeSpaces(corAccount),
          },
        },
      }));
    });

    return (
      <HelperText
        status="error"
        message={
          errors?.root?.serverError.type &&
          t('COMMON:errors.duplicatedData', {
            code: errors.root.serverError.type,
          })
        }
      >
        <SubmitButton
          variant="primary"
          label={t('COMMON:buttons.continue') || ''}
          onClick={submitPage}
          disabled={!!Object.keys(errors).length || isLoading}
          isLoading={isFetching}
          {...addTestAttribute('agentBankDetails.submitButton')}
        />
      </HelperText>
    );
  }
);
