import { useCallback, useMemo } from 'react';
import { FeatureFlags } from '@src/shared/constants';

import { useStorageQuery } from './use-storage-query';

export const useFeatureFlags = (featureFlags: FeatureFlags[]) => {
  const queryResult = useStorageQuery<{ features: string[] }, [undefined]>(
    'featureFlags',
    '/themes/common-features.json',
    undefined,
    {
      cacheTime: Infinity,
    }
  );

  const {
    isLoading: isFeatureFlagsLoading,
    error: featureFlagsError,
    data,
    refetch: featureFlagsRefetch,
    isFetching: isFeatureFlagsFetching,
  } = queryResult;

  const getCurrentFlags = useCallback(() => {
    if (!isFeatureFlagsLoading && data?.data?.features) {
      return featureFlags.map((flag) => data.data.features.includes(flag));
    }
    return Array(featureFlags.length).fill(false);
  }, [featureFlags, data?.data?.features, isFeatureFlagsLoading]);

  const res = useMemo(getCurrentFlags, [isFeatureFlagsLoading]);

  return {
    res,
    isFeatureFlagsLoading,
    featureFlagsError,
    featureFlagsRefetch,
    isFeatureFlagsFetching,
  };
};
