import { StateBlock as StateBlockBase } from '@pulse-web-ui/state-block';
import { styled } from '@pulse-web-ui/theme';

export const StateBlock = styled(StateBlockBase)`
  & h1 {
    font-weight: 600;
  }

  & div[variant='primary'] {
    color: ${({ theme }) => theme.colors.text.inverse};
    font-weight: 600;
  }
`;
