import actsListDictionary from '@widgets/acts-list/i18n/ru/acts-list.dic.json';
import actsPaymentInfoDictionary from '@widgets/acts-payment-info/i18n/ru/acts-payment-info.dic.json';
import agentActCardDictionary from '@entities/agent-act/i18n/ru/agent-act.dic.json';
import actsFiltersDictionary from '@widgets/acts-filters/i18n/acts-filters.dic.json';

export const actsList = {
  ACTS_LIST: actsListDictionary,
  ACTS_PAYMENT_INFO: actsPaymentInfoDictionary,
  AGENT_ACT_CARD: agentActCardDictionary,
  ACTS_FILTERS: actsFiltersDictionary,
};

export const actsListNamespaces = Object.keys(actsList);
