import { AddressType } from '@shared/index';
import type { Profile } from '@entities/agent-contract';
import type { AgentBranch } from '@entities/index';

export const AGENT_DEFAULT_BRANCH_CODE = '17714021';

interface GetDefaultAgentEmploymentDetailsBranchCode {
  profile?: Profile;
  agentBranchDictionary?: AgentBranch[];
}

export const getDefaultAgentEmploymentDetailsBranchCode = ({
  profile,
  agentBranchDictionary,
}: GetDefaultAgentEmploymentDetailsBranchCode) => {
  // Handle Imported case.
  if (profile?.isImported) {
    const branch = agentBranchDictionary?.find(
      ({ code }) => code === profile?.employmentDetails?.branchCode
    );

    return branch?.code ?? '';
    // Alternative scenario
  }
  // Get user accommodation address
  const userAddress = profile?.addressesAgents?.find(
    (address) => address?.addressType === AddressType.ACCOMMODATION
  );

  return agentBranchDictionary?.reduce((code, currBranch) => {
    // Try to find branch in dictionary by fiasCode and regionCode
    if (currBranch.fiasCode === userAddress?.regionCode) {
      code = currBranch.code;
    } else if (!code && currBranch?.code === AGENT_DEFAULT_BRANCH_CODE) {
      // If branch is not found than return default branch
      code = currBranch.code;
    }
    return code;
  }, '');
};
