export const PlatformType = 'WEB';

export enum AuthEndpoints {
  LOGIN = '/login',
  LOGOUT = '/logout',
}
export enum AgentEndpoints {
  AGENTS = '/api/external/agents/v1',
  AGENT_SALE = '/api/external/agent-sale/v1',
  AGENT_ACTS = '/api/external/agent-acts/v1',
  AGENT_CONSENT = '/api/external/consent/v1/agent',
  AGENT_SRG = '/api/external/agents-srg/v1',
  AGENT_STANDARDS = '/api/external/standard/v1',
  REFERENCE_INFORMATION = '/api/external/reference-information/v1',
  NOTIFICATIONS = '/api/external/notifications/v1',
  PRINTED_CONTRACTS = '/api/external/contracts/v1/printed-form',
}

export enum DictionariesEndpoints {
  DICTIONARIES = '/api/common/v1/dictionaries',
}

export enum RequestMethods {
  POST = 'post',
  GET = 'get',
  PATCH = 'patch',
  PUT = 'put',
}
