import i18n, { use } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { namespaces, resources } from './resources';

use(initReactI18next).init({
  returnNull: false,
  lng: 'ru',
  ns: namespaces,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  react: {
    bindI18nStore: 'added',
    transSupportBasicHtmlNodes: true,
  },
  resources,
});

export default i18n;
