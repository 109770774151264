import { TFunction } from 'i18next';

import { BankDetails } from '@entities/agent-contract';
import { ProfilePersonalData } from '../model';
import { Gender } from '@src/shared';

export const getBankDetailsTranslations = (
  t: TFunction
): Record<keyof BankDetails, string> => ({
  bic: t('AGENT_PROFILE:labels.bic'),
  nameBank: t('AGENT_PROFILE:labels.bankName'),
  corAccount: t('AGENT_PROFILE:labels.corAccount'),
  bankAccount: t('AGENT_PROFILE:labels.bankAccount'),
});

export const getPersonalDataTranslations = (
  t: TFunction
): Partial<Record<keyof ProfilePersonalData, string>> => ({
  lastName: t('AGENT_PROFILE:labels.lastName'),
  firstName: t('AGENT_PROFILE:labels.firstName'),
  middleName: t('AGENT_PROFILE:labels.middleName'),
  gender: t('AGENT_PROFILE:labels.gender'),
  agentCode: t('AGENT_PROFILE:labels.agentCode'),
  birthDate: t('AGENT_PROFILE:labels.birthDate'),
  phone: t('AGENT_PROFILE:labels.phone'),
  email: t('AGENT_PROFILE:labels.email'),
  address: t('AGENT_PROFILE:labels.address'),
  actualAddress: t('AGENT_PROFILE:labels.actualAddress'),
  passportSeriesNumber: t('AGENT_PROFILE:labels.passportSeriesNumber'),
  codeDepartment: t('AGENT_PROFILE:labels.departmentCode'),
  nameDepartment: t('AGENT_PROFILE:labels.passportIssuedBy'),
  dateIssue: t('AGENT_PROFILE:labels.issueDate'),
  snils: t('AGENT_PROFILE:labels.snils'),
  inn: t('AGENT_PROFILE:labels.inn'),
  website: t('AGENT_PROFILE:labels.website'),
});

export const getGenderLabels = (t: TFunction) => ({
  [Gender.MALE]: t('PERSONAL_DOCUMENTS:options.male'),
  [Gender.FEMALE]: t('PERSONAL_DOCUMENTS:options.female'),
});
