import { makeAutoObservable, runInAction } from 'mobx';

import { Main } from '@app/store';
import {
  Profile,
  Address,
  AgentDocument,
  BankDetails,
  PersonalDocuments,
  EmploymentDetails,
} from '../types';

//TODO: переименовать в AgentProfileStore, объединить с AgentUserStore и удалить AgentUserStore
export class AgentContractStore {
  private mainStore: Main;
  profile?: Profile;
  agentDocuments?: AgentDocument[];

  constructor(mainStore: Main) {
    this.mainStore = mainStore;
    makeAutoObservable(this);
  }

  setPersonalDocuments = (personalDocument: PersonalDocuments) => {
    this.profile = {
      ...this.profile,
      personalDocument,
    };
  };

  setAddresses = (addressesAgents: Array<Address>) => {
    this.profile = {
      ...this.profile,
      addressesAgents,
    };
  };

  setEmploymentDetails = (employmentDetails: EmploymentDetails) => {
    this.profile = {
      ...this.profile,
      employmentDetails,
    };
  };

  setBankDetails = (bankDetails: BankDetails) => {
    this.profile = {
      ...this.profile,
      bankDetails,
    };
  };

  setAgentDocuments = (documents: AgentDocument[]) => {
    this.agentDocuments = documents;
  };

  updateProfile = (newProfile: Profile) => {
    this.profile = {
      ...this.profile,
      ...newProfile,
    };
  };

  reset() {
    runInAction(() => {
      this.profile = undefined;
      this.agentDocuments = undefined;
    });
  }
}
