import { ErrorBoundary } from 'react-error-boundary';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';

import { StyledThemeProvider, rgsTheme } from '@pulse-web-ui/theme';

import './i18n/config';
import { AppContent } from './app-content';
import { ErrorBoundaryFallback } from './fallbacks';
import { Layout } from './layout';
import { WebAnalytic } from './web-analytic';

export const App = () => {
  const { reset } = useQueryErrorResetBoundary();

  return (
    <>
      <WebAnalytic />
      <StyledThemeProvider theme={rgsTheme}>
        <Layout>
          <ErrorBoundary
            onReset={reset}
            FallbackComponent={ErrorBoundaryFallback}
          >
            <AppContent />
          </ErrorBoundary>
        </Layout>
      </StyledThemeProvider>
    </>
  );
};

export default App;
