import { useState, useCallback, memo, lazy, Suspense } from 'react';
import { Modal } from '@pulse-web-ui/modal';

import {
  HintButton,
  StyledTitle,
  StyledContent,
  StyledDescription,
  CloseButton,
  HeaderWrapper,
} from './button-with-modal.styles';
import { SubmitButton } from '../buttons';
import { useTheme } from '@pulse-web-ui/theme';

const Close = lazy(() => import('@pulse-web-ui/icons/lib/lazy/close'));

interface Props {
  label: string;
  title?: string;
  description?: string;
  submitLabel?: string;
}

export const ButtonWithModal = memo(
  ({ label, title, description, submitLabel }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const theme: any = useTheme();
    const handleCloseModal = useCallback(() => setIsOpen(false), []);
    const handleOpenModal = useCallback(() => setIsOpen(true), []);

    return (
      <>
        <HintButton label={label} variant="text" onClick={handleOpenModal} />
        <Modal
          closeIcon={false}
          showDialog={isOpen}
          closeHandler={handleCloseModal}
        >
          <StyledContent>
            <HeaderWrapper>
              {title && <StyledTitle>{title}</StyledTitle>}
              <CloseButton onClick={handleCloseModal}>
                <Suspense fallback={null}>
                  <Close width={20} color={theme.colors.icon.primary} />
                </Suspense>
              </CloseButton>
            </HeaderWrapper>

            {description && (
              <StyledDescription>{description}</StyledDescription>
            )}
            <SubmitButton label={submitLabel} onClick={handleCloseModal} />
          </StyledContent>
        </Modal>
      </>
    );
  }
);
