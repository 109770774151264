import { useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useErrorBoundary } from 'react-error-boundary';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { ArrowRight } from '@pulse-web-ui/icons';
import { Input } from '@pulse-web-ui/input';
import { useTheme } from '@pulse-web-ui/theme';

import {
  BoundaryErrorType,
  MAIN,
  SubmitButton,
  addTestAttribute,
  allowOnlyNumbers,
  getMismatches,
  phoneDisplayValueCasting,
  phoneValueCasting,
  useStores,
  FlkCode,
} from '@shared/index';
import type { ChangePasswordFormProps } from '@widgets/change-password-form/model';
import type { PasswordRecoveryFormProps } from '@widgets/password-recovery-form/model';
import type { CreatePasswordFormProps } from '@features/create-password';
import { ChangePhone } from '@features/index';

import { useRefreshCode, usePassword } from '../../api';
import { Resend } from '../resend';

import {
  ButtonCaption,
  CodeContainer,
  CodeInputWrapper,
  StyledButton,
  SubmitButtonWrapper,
} from './embedded-otp.styles';

const OTP_MAX_LENGTH = 5;
export interface EmbeddedOtpProps {
  navigateToStepOne: (withError?: boolean) => void;
  isTriggered?: boolean;
}

const requestId = uuidv4();

export const EmbeddedOtp = observer(
  ({ navigateToStepOne, isTriggered }: EmbeddedOtpProps) => {
    const {
      MainStore: {
        agentUserStore: { setAuthSuccessfulText },
      },
    } = useStores();
    const theme: any = useTheme();
    const [isResendFetching, setIsResendFetching] = useState<boolean>(false);
    const [isReadyToSubmit, setIsReadyToSubmit] = useState<boolean>(false);
    const [isReadyToChangePassword, setIsReadyToChangePassword] =
      useState<boolean>(false);
    const [otpToken, setOtpToken] = useState<string | undefined>();
    const navigate = useNavigate();

    const otpCodeRef = useRef<HTMLInputElement | null>(null);

    const { t } = useTranslation();
    const { showBoundary } = useErrorBoundary();
    const {
      control,
      getValues,
      formState: { dirtyFields, errors },
      setError,
      setValue,
      watch,
      clearErrors,
      trigger,
    } = useFormContext<
      PasswordRecoveryFormProps &
        CreatePasswordFormProps &
        ChangePasswordFormProps
    >();

    const {
      res: refreshCodeRes,
      refetch: refreshCodeRefetch,
      error: refreshCodeError,
      isFetching: isRefreshCodeFetching,
    } = useRefreshCode({
      phone: phoneValueCasting(getValues('phone')),
      deps: [getValues('phone'), requestId],
    });

    const { res, refetch, error, isFetching } = usePassword({
      phone: phoneValueCasting(getValues('phone')),
      request: {
        newPassword: getValues('password'),
        code: getValues('code'),
        token: getValues('token'),
      },
      deps: [
        getValues('phone'),
        getValues('password'),
        getValues('code'),
        getValues('token'),
      ],
    });

    const handleResendSuccess = (token: string) => {
      setIsResendFetching(false);
      setValue('token', token);
      setOtpToken(token);
      setValue('code', '');
      clearErrors();
    };

    const handleResend = () => {
      setIsResendFetching(true);
    };
    const handleResendError = (message: string) => {
      setIsResendFetching(false);
      setError('phone', { message });
      setIsReadyToSubmit(false);
      setOtpToken(undefined);
      setIsReadyToChangePassword(false);

      const timeoutId = setTimeout(() => {
        navigateToStepOne();
        clearTimeout(timeoutId);
      }, 200);
    };

    const handleEnteringCode = () => {
      trigger(['password', 'confirmPassword']).then(() => {
        setIsReadyToChangePassword(true);
      });
      if (
        !dirtyFields.code ||
        !!errors.code ||
        !!errors.password ||
        !!errors.confirmPassword
      )
        return;
    };

    const handleSubmit = () => {
      trigger(['password', 'confirmPassword']).then(() => {
        setIsReadyToSubmit(true);
      });
    };

    useEffect(() => {
      const onlyNumValue = allowOnlyNumbers(otpCodeRef.current?.value || '');
      setValue('code', onlyNumValue);
    }, [watch('code')]);

    useEffect(() => {
      if (isTriggered !== true) {
        setIsReadyToSubmit(false);
        setIsReadyToChangePassword(false);
      }
    }, [isTriggered, isReadyToSubmit, isReadyToChangePassword]);

    useEffect(() => {
      if (
        isReadyToSubmit &&
        isTriggered &&
        !!getValues('password') &&
        !errors.password &&
        !!getValues('confirmPassword') &&
        !errors.confirmPassword
      ) {
        refreshCodeRefetch();
      }
    }, [isReadyToSubmit, isTriggered, errors.password, errors.confirmPassword]);

    useEffect(() => {
      if (
        isReadyToChangePassword &&
        isTriggered &&
        !!getValues('password') &&
        !errors.password &&
        !!getValues('confirmPassword') &&
        !errors.confirmPassword
      ) {
        refetch();
      }
    }, [
      isReadyToChangePassword,
      isTriggered,
      errors.password,
      errors.confirmPassword,
    ]);

    useEffect(() => {
      if (!isFetching && res?.isConfirmed) {
        setAuthSuccessfulText(t('COMMON:subHeaders.completePasswordRecovery'));
        navigate(MAIN, { replace: true });
      }
    }, [isFetching, res]);

    useEffect(() => {
      if (!isRefreshCodeFetching && refreshCodeRes) {
        setValue('token', refreshCodeRes?.token);
        setOtpToken(refreshCodeRes?.token);
      }
    }, [isRefreshCodeFetching, refreshCodeRes]);

    useEffect(() => {
      if (refreshCodeError) {
        const mismatches = getMismatches(refreshCodeError);

        if (!!mismatches) {
          mismatches.forEach(({ code }) => {
            if (
              code === FlkCode.OTP_0101_0001 ||
              code === FlkCode.OTP_0101_0002 ||
              code === FlkCode.OTP_0101_0003 ||
              code === FlkCode.RMM_6040_0002
            ) {
              // Неверный номер телефона
              setError('phone', {
                message: t('COMMON:errors.incorrectPhoneNumber'),
              });
              setIsReadyToSubmit(false);
              setOtpToken(undefined);
              setIsReadyToChangePassword(false);

              const timeoutId = setTimeout(() => {
                navigateToStepOne && navigateToStepOne(true);
                clearTimeout(timeoutId);
              }, 200);
            } else {
              showBoundary(BoundaryErrorType.SomethingWentWrong);
            }
          });
        } else {
          showBoundary(BoundaryErrorType.SomethingWentWrong);
        }
      }
    }, [refreshCodeError]);

    useEffect(() => {
      if (error) {
        const mismatches = getMismatches(error);

        if (!!mismatches) {
          let hasKnownErrors = false;
          mismatches.forEach(({ code }) => {
            if (code === FlkCode.RMM_6020_0005) {
              // Запрещено сменять пароль на текущий
              setError('confirmPassword', {
                message: t('OTP:errors.forbiddenToChangeToCurrent'),
              });
              setError('password', {
                message: t('OTP:errors.forbiddenToChangeToCurrent'),
              });

              setOtpToken(undefined);
              setValue('code', '');
              setValue('token', '');

              hasKnownErrors = true;
            } else if (code === FlkCode.RMM_6020_0006) {
              // Указанный пароль был ранее использован. Придумайте новый пароль
              setError('confirmPassword', {
                message: t('OTP:errors.passwordWasPreviouslyUsed'),
              });
              setError('password', {
                message: t('OTP:errors.passwordWasPreviouslyUsed'),
              });

              setOtpToken(undefined);
              setValue('code', '');
              setValue('token', '');

              hasKnownErrors = true;
            } else if (
              code === FlkCode.OTP_0201_0001 ||
              code === FlkCode.OTP_0201_0002 ||
              code === FlkCode.OTP_0201_0003
            ) {
              // Неверный код из SMS
              setError('code', {
                message: t('OTP:errors.incorrectSmsCode'),
              });

              hasKnownErrors = true;
            } else if (
              code === FlkCode.RMM_6020_0001 ||
              code === FlkCode.RMM_6020_0002 ||
              code === FlkCode.RMM_6020_0003 ||
              code === FlkCode.RMM_6020_0004 ||
              code === FlkCode.RMM_6020_0007 ||
              code === FlkCode.RMM_6020_0008
            ) {
              // Что-то пошло не так
              hasKnownErrors = false;
            }
          });
          // Что-то пошло не так
          if (!hasKnownErrors) {
            showBoundary(BoundaryErrorType.SomethingWentWrong);
          }
        } else {
          // Что-то пошло не так
          showBoundary(BoundaryErrorType.SomethingWentWrong);
        }
      }
    }, [error]);

    const getHintObject = (message: string) => ({
      hintObject: {
        message,
      },
    });

    return (
      <>
        {otpToken ? (
          <>
            <CodeContainer>
              <CodeInputWrapper>
                <Controller
                  control={control}
                  name="code"
                  render={({ field: { value, onChange }, fieldState }) => (
                    <Input
                      label={t('OTP:labels.smsCode') || ''}
                      value={value || ''}
                      ref={(e) => (otpCodeRef.current = e)}
                      onChange={(e) => {
                        clearErrors();
                        onChange(e);
                      }}
                      maxLength={OTP_MAX_LENGTH}
                      error={!!fieldState.error?.message}
                      placeholder={'00000'}
                      inputMode="numeric"
                      {...(fieldState.error?.message
                        ? getHintObject(fieldState.error?.message)
                        : {})}
                      {...addTestAttribute('otp-code-input')}
                    />
                  )}
                />
                <Resend
                  onSuccess={handleResendSuccess}
                  onError={handleResendError}
                  onResend={handleResend}
                  phone={phoneValueCasting(getValues('phone'))}
                />
              </CodeInputWrapper>
              <StyledButton
                variant="square"
                onClick={handleEnteringCode}
                disabled={!!errors.code}
                error={errors.code?.message as string}
                isLoading={isResendFetching || isFetching}
                {...addTestAttribute('otp-button-send')}
              >
                <ArrowRight width={30} color={theme.colors.icon.inverse} />
              </StyledButton>
            </CodeContainer>
            <ChangePhone navigateToStepOne={navigateToStepOne} />
          </>
        ) : (
          <>
            <SubmitButtonWrapper>
              <SubmitButton
                variant="primary"
                label={t('COMMON:buttons.continue') || ''}
                onClick={handleSubmit}
                isLoading={isRefreshCodeFetching}
                disabled={!!errors.password || !!errors.confirmPassword}
                {...addTestAttribute('otp-button-continue')}
              />
            </SubmitButtonWrapper>
            <ButtonCaption>
              {t('OTP:hints.caption', {
                phone: phoneDisplayValueCasting(getValues('phone')),
              })}
            </ButtonCaption>
          </>
        )}
      </>
    );
  }
);
