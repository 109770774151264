import { default as Axios } from 'axios';

export const axiosInstance = Axios.create({
  withCredentials: true,
  baseURL: window.envUrls.BASEURL,
});

export const dadataAxiosInstance = Axios.create({
  baseURL: window.envUrls.DADATA_URL,
});

export enum InstanceUrls {
  BASEURL = 'BASEURL',
  DADATA_URL = 'DADATA_URL',
}

export const instance = {
  BASEURL: axiosInstance,
  DADATA_URL: dadataAxiosInstance,
};
