/* eslint-disable indent */
import { styled, typography } from '@pulse-web-ui/theme';

export const TooltipTitle = styled.div`
  ${typography.regular14}
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text.secondary};
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
`;
export const TooltipSubTitle = styled.div`
  margin-bottom: 20px;
`;

export const TooltipList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  list-style-position: inside;
`;

export const TooltipListItem = styled.li`
  cursor: pointer;

  a {
    color: ${({ theme }) =>
      theme.common?.a?.color
        ? theme.common.a.color
        : theme.colors.text.interactive};
    text-decoration: ${({ theme }) =>
      theme.common?.a?.decoration ? theme.common.a.decoration : 'none'};
  }
`;

export const StyledTooltipLink = styled.div`
  cursor: pointer;

  a {
    color: ${({ theme }) =>
      theme.common?.a?.color
        ? theme.common.a.color
        : theme.colors.text.interactive};
    text-decoration: ${({ theme }) =>
      theme.common?.a?.decoration ? theme.common.a.decoration : 'none'};
  }
`;
