import loginDictionary from '@entities/login/i18n/ru/login.dic.json';
import submitLoginDictionary from '@features/submit-login/i18n/ru/submit-login.dic.json';
import passwordRecoveryButtonDictionary from '@src/features/password-recovery-button/i18n/ru/password-recovery-button.dic.json';

export const login = {
  LOGIN: loginDictionary,
  SUBMIT_LOGIN: submitLoginDictionary,
  PASSWORD_RECOVERY_BUTTON: passwordRecoveryButtonDictionary,
};

export const loginNamespaces = [
  'LOGIN',
  'SUBMIT_LOGIN',
  'PASSWORD_RECOVERY_BUTTON',
];
