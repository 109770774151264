import { media, styled } from '@pulse-web-ui/theme';

export const PageHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  margin-bottom: 16px;
  box-sizing: border-box;
  padding: 0 16px;

  ${media.desktop} {
    padding: 0 24px;
  }
`;
