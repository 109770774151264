import * as yup from 'yup';
import i18n from '@src/app/i18n/config';
import {
  AgentEmploymentType,
  AgentType,
  allowOnlyNumbers,
  PersonalDocumentMaxLength,
} from '@src/shared';

export const agentEmploymentDetailsSchema = yup
  .object()
  .shape({
    branchCode: yup
      .string()
      .required(i18n.t('AGENT_EMPLOYMENT_DETAILS:errors.specifyBranchCode')),
    employmentType: yup
      .mixed<AgentEmploymentType>()
      .oneOf(Object.values(AgentEmploymentType))
      .required(i18n.t('AGENT_EMPLOYMENT_DETAILS:errors.employmentType')),
    agentType: yup
      .mixed<AgentType>()
      .oneOf(Object.values(AgentType))
      .required(i18n.t('AGENT_EMPLOYMENT_DETAILS:errors.agentType')),
    ogrnIp: yup.string().when('agentType', {
      is: AgentType.IP,
      then: () =>
        yup
          .string()
          .required(i18n.t('COMMON:errors.required'))
          .test(
            'length',
            i18n.t('COMMON:errors.required'),
            (val) =>
              allowOnlyNumbers(val).length === PersonalDocumentMaxLength.OGRNIP
          ),
    }),
    dateIpRegistration: yup.string().when('agentType', {
      is: AgentType.IP,
      then: () =>
        yup
          .string()
          .required(
            i18n.t('AGENT_EMPLOYMENT_DETAILS:errors.dateIpRegistration')
          ),
    }),
  })
  .required();
