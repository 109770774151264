import { action } from 'mobx';
import type { Option } from '@pulse-web-ui/autocomplete-select';
import { AgentBranch } from '../../model';

export const getAgentBranchOptions = action(
  (agentBranchData: AgentBranch[] | undefined): Option[] | [] => {
    if (!agentBranchData) return [];

    return agentBranchData.map((item: AgentBranch) => ({
      label: item.name,
      value: item.code,
    }));
  }
);
