import { useRequest, RequestMethods, AgentEndpoints } from '@src/shared';
import type { CommonResponse, RequestDeps } from '@src/shared/types';
import type {
  GetDocumentRequestParams,
  GetDocumentResponse,
} from './get-document.types';

export const useGetDocument = (
  request: GetDocumentRequestParams,
  deps: RequestDeps
) => {
  const { res, isLoading, refetch, error, status, isFetching } = useRequest<
    GetDocumentRequestParams,
    GetDocumentResponse,
    CommonResponse
  >({
    params: {
      key: 'getDocument',
      method: RequestMethods.POST,
      url: `${AgentEndpoints.AGENTS}/agent-contracts`,
      request,
      fetchManual: true,
      deps,
    },
    config: { responseType: 'blob' },
  });

  return {
    res,
    error,
    isLoading,
    refetch,
    status,
    isFetching,
  };
};
