import { Button } from '@pulse-web-ui/button';
import { styled, typography } from '@pulse-web-ui/theme';
import { IconButton } from '@shared/index';

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CloseButton = styled(IconButton)`
  width: 32px;
  height: 32px;
  border-radius: ${({ theme }) => theme.common?.radius};
`;

export const HintButton = styled(Button)`
  ${typography.regular14};
  line-height: 20px;
  margin-bottom: 16px;
`;

export const StyledTitle = styled.h3`
  width: 100%;
  text-align: left;
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: 0;
`;

export const StyledDescription = styled.div`
  width: 100%;
  margin-top: 4px;
`;

export const StyledContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;
