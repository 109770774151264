import amplitude from 'amplitude-js';
import { FC, memo, useEffect } from 'react';
import { YMInitializer } from 'react-yandex-metrika';

const YM_COUNTER_KEY = Number(window.envKeys.YM_AGENT_COUNTER_KEY);
const AMPLITUDE_API_KEY = String(window.envKeys.AMPLITUDE_AGENT_API_KEY);

export const WebAnalytic: FC = memo(() => {
  useEffect(() => {
    amplitude.getInstance().init(AMPLITUDE_API_KEY, undefined, {});
  }, [AMPLITUDE_API_KEY]);

  return (
    <YMInitializer
      accounts={[Number(YM_COUNTER_KEY)]}
      options={{
        triggerEvent: true,
        childIframe: true,
        trackLinks: true,
        accurateTrackBounce: true,
        clickmap: true,
        webvisor: true,
        trustedDomains: ['https://pulse.rusrobots.ru/', 'https://pulse.insure'],
      }}
      version="2"
    />
  );
});
