export enum ConsentType {
  NonAcceptanceDebit = '001',
  ActiveCommunication = '002',
  PersonalDataManagement = '003',
  DigitalSignature = '004',
  MobileApp = '005',
  Insurance = '006',
  AgentDataManagement = '007',
  ElectronicDocumentManagement = '008',
  AgentActiveCommunication = '009',
  DistributionPersonalDataManagement = '010',
}
