import React, { useCallback, useEffect, useState } from 'react';
import type { FocusEvent } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Input } from '@pulse-web-ui/input';
import { AutocompleteSelect } from '@pulse-web-ui/autocomplete-select';
import type { Option } from '@pulse-web-ui/autocomplete-select';
import { HelperText } from '@pulse-web-ui/helper-text';

import {
  FormControlContainer,
  addTestAttribute,
  useStores,
} from '@shared/index';

import {
  getBankNameByBic,
  getBicOptions,
  getCorAccountByBankName,
} from '../../dictionaries';
import { AgentBankDetailsFormParams } from '../model';
import { observer } from 'mobx-react-lite';
import { AgentBankDetailsWrapper } from './agent-bank-details.styles';
import {
  BIC_PLACEHOLDER,
  NAME_BANK_MAX_LENGTH,
  ACCOUNT_MASK,
  ACCOUNT_PLACEHOLDER,
} from '../model/constants';

interface AgentBankDetailsProps {
  setIsLoading: (isLoading: boolean) => void;
}

export const AgentBankDetails = observer(
  ({ setIsLoading }: AgentBankDetailsProps) => {
    const { t } = useTranslation();
    const {
      MainStore: {
        dictionariesStore: { bicDictionary, isBicDictionaryLoaded },
        agentContractStore: { profile },
      },
    } = useStores();
    const [options, setOptions] = useState<Option[]>([]);
    const [isCorAccountDisabled, setIsCorAccountDisabled] =
      useState<boolean>(true);
    const [isBankAccountDisabled, setIsBankAccountDisabled] =
      useState<boolean>(true);

    const {
      control,
      formState: { errors },
      clearErrors,
      getValues,
      setError,
      setValue,
    } = useFormContext<AgentBankDetailsFormParams>();

    const handleBicChange = useCallback(
      (
        value: string | undefined,
        onChange: (value: string | undefined) => void
      ) => {
        if (!!value) {
          if (errors) {
            clearErrors();
          }

          const bankName =
            getBankNameByBic(
              bicDictionary,
              value?.replace(/[^0-9]/g, '') || ''
            ) || '';
          setValue('nameBank', bankName || '');
          setValue('bankAccount', '');
          setIsBankAccountDisabled(false);
          getCorAccount(bankName);
          onChange(value);
        } else {
          setValue('bic', '');
        }
      },
      [bicDictionary]
    );

    const getCorAccount = useCallback(
      (bankName: string) => {
        const corAcount = getCorAccountByBankName(
          bicDictionary,
          bankName || ''
        );
        if (corAcount) {
          setValue('corAccount', corAcount);
          setIsCorAccountDisabled(true);
        } else {
          setValue('corAccount', '');
          setIsCorAccountDisabled(false);
        }

        setIsBankAccountDisabled(false);
      },
      [bicDictionary]
    );

    const handleBicFieldChange = useCallback(
      (bicValue: string | undefined) => {
        if (errors) {
          clearErrors();
        }
        const digitalValue = bicValue?.replace(/[^0-9]/g, '');

        if (!!digitalValue) {
          const foundedName = getBankNameByBic(bicDictionary, digitalValue);

          if (foundedName) {
            setValue('bic', digitalValue);
            setValue('nameBank', foundedName);
            setValue('bankAccount', '');
            getCorAccount(foundedName);
          }
        } else {
          setValue('bic', '');
          setValue('nameBank', '');
          setValue('corAccount', '');
          setValue('bankAccount', '');
        }
      },
      [bicDictionary]
    );

    const handleBicBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        const bicValue = event.target.value;

        if (
          !!bicValue &&
          !options?.some((option) => option.label.includes(bicValue))
        ) {
          setError('bic', {
            type: 'string',
            message: t('AGENT_BANK_DETAILS:errors.bicNotFound'),
          });
          setValue('nameBank', '');
          setValue('corAccount', '');
          setValue('bankAccount', '');
          setIsCorAccountDisabled(true);
          setIsBankAccountDisabled(true);
        }
      },
      [options]
    );

    useEffect(() => {
      if (isBicDictionaryLoaded && options.length === 0) {
        setOptions(getBicOptions(bicDictionary));
      }
    }, [isBicDictionaryLoaded, options.length]);

    useEffect(() => {
      if (!!options.length) {
        setValue('bic', profile?.bankDetails?.bic || '');
        setIsBankAccountDisabled(false);

        const corAcount = getCorAccountByBankName(
          bicDictionary,
          getValues('nameBank') || ''
        );

        if (corAcount) {
          setIsCorAccountDisabled(true);
        } else {
          setIsCorAccountDisabled(false);
        }

        setIsLoading(false);
      }
    }, [options.length]);

    return (
      <AgentBankDetailsWrapper>
        <FormControlContainer>
          <Controller
            control={control}
            name="bic"
            render={({ field: { onChange, value }, fieldState }) => (
              <HelperText
                status={!!fieldState.error ? 'error' : 'default'}
                message={errors.bic?.message}
              >
                <AutocompleteSelect
                  onChange={(value) => {
                    if (errors) {
                      clearErrors();
                    }
                    handleBicChange(value, onChange);
                  }}
                  fieldOnChange={handleBicFieldChange}
                  onBlur={handleBicBlur}
                  arrowWidth={49}
                  value={value || ''}
                  options={options}
                  label={t('AGENT_BANK_DETAILS:labels.bic') || ''}
                  status={fieldState.error && 'error'}
                  isLoading={options?.length === 0}
                  placeholder={BIC_PLACEHOLDER}
                  testId="agentBankDetails.bic"
                />
              </HelperText>
            )}
          />
        </FormControlContainer>
        <FormControlContainer>
          <Controller
            control={control}
            name="nameBank"
            render={({ field: { onChange, value }, fieldState }) => (
              <HelperText
                status={!!fieldState.error ? 'error' : 'default'}
                message={errors.nameBank?.message}
              >
                <Input
                  label={t('AGENT_BANK_DETAILS:labels.name') || ''}
                  id="nameBank"
                  name="nameBank"
                  value={value}
                  onChange={(val) => {
                    if (errors) {
                      clearErrors();
                    }
                    onChange(val);
                  }}
                  error={!!errors.nameBank}
                  maxLength={NAME_BANK_MAX_LENGTH}
                  disabled
                  {...addTestAttribute('agentBankDetails.bankName')}
                />
              </HelperText>
            )}
          />
        </FormControlContainer>
        <FormControlContainer>
          <Controller
            control={control}
            name="corAccount"
            render={({ field: { onChange, value }, fieldState }) => (
              <HelperText
                status={!!fieldState.error ? 'error' : 'default'}
                message={errors.corAccount?.message}
              >
                <Input
                  label={t('AGENT_BANK_DETAILS:labels.corAccount') || ''}
                  id="corAccount"
                  name="corAccount"
                  value={value}
                  onChange={(val) => {
                    if (errors) {
                      clearErrors();
                    }
                    onChange(val);
                  }}
                  error={!!errors.corAccount}
                  mask={ACCOUNT_MASK}
                  placeholder={ACCOUNT_PLACEHOLDER}
                  disabled={isCorAccountDisabled}
                  {...addTestAttribute('agentBankDetails.corAccount')}
                />
              </HelperText>
            )}
          />
        </FormControlContainer>
        <FormControlContainer>
          <Controller
            control={control}
            name="bankAccount"
            render={({ field: { onChange, value }, fieldState }) => (
              <HelperText
                status={!!fieldState.error ? 'error' : 'default'}
                message={
                  errors.bankAccount?.message ||
                  t('AGENT_BANK_DETAILS:hints.bankAccount')
                }
              >
                <Input
                  label={t('AGENT_BANK_DETAILS:labels.bankAccount') || ''}
                  id="bankAccount"
                  name="bankAccount"
                  value={value}
                  onChange={(val) => {
                    if (errors) {
                      clearErrors();
                    }
                    onChange(val);
                  }}
                  error={!!errors.bankAccount}
                  mask={ACCOUNT_MASK}
                  placeholder={ACCOUNT_PLACEHOLDER}
                  disabled={isBankAccountDisabled}
                  {...addTestAttribute('agentBankDetails.bankAccount')}
                />
              </HelperText>
            )}
          />
        </FormControlContainer>
      </AgentBankDetailsWrapper>
    );
  }
);
