import { css, styled, typography } from '@pulse-web-ui/theme';

interface InputProps {
  error?: boolean;
}

export const StyledOtpInput = styled.input<InputProps>`
  ${typography.regular18};
  width: 48px !important;
  height: 56px;
  outline: none;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors?.text.primary};
  border-radius: ${({ theme }) => theme.common?.radius};

  ${({ theme, error }) =>
    !!error
      ? css`
          border: 1px solid ${theme.colors?.accent.error.normal};
        `
      : css`
          border: 1px solid ${theme.colors?.neutral[30]};
        `};
`;

export const InputSeparator = styled.div`
  width: 8px;
`;

export const OtpController = styled.div`
  margin-bottom: 16px;
`;
