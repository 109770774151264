import { ReactNode } from 'react';

import { Label, Value } from './label-value.styles';

interface Props {
  label: string;
  value: string | ReactNode;
}

export const LabelValue = ({ label, value }: Props) => {
  return (
    <div>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </div>
  );
};
