import * as yup from 'yup';
import i18n from '@src/app/i18n/config';
import { allowOnlyNumbers, checkKS, checkRS, removeSpaces } from '@src/shared';
import { ACCOUNT_MAX_LENGTH } from '../constants';

export const agentBankDetailsSchema = yup
  .object()
  .shape({
    bic: yup.string().required(i18n.t('COMMON:errors.required')),
    bankAccount: yup
      .string()
      .required(i18n.t('COMMON:errors.required'))
      .test(
        'length',
        i18n.t('COMMON:errors.required', { length: ACCOUNT_MAX_LENGTH }),
        (val) => allowOnlyNumbers(val)?.length === ACCOUNT_MAX_LENGTH
      )
      .when('bic', (bic: string[], schema) => {
        if (!!bic?.[0]) {
          return schema.test(
            'validBankAccount',
            i18n.t('AGENT_BANK_DETAILS:errors.bankAccountInvalid'),
            (val) => checkRS(removeSpaces(val), bic[0])
          );
        }

        return schema;
      }),
    nameBank: yup.string().required(i18n.t('COMMON:errors.required')),
    corAccount: yup
      .string()
      .required(i18n.t('COMMON:errors.required'))
      .test(
        'length',
        i18n.t('COMMON:errors.required', { length: ACCOUNT_MAX_LENGTH }),
        (val) => allowOnlyNumbers(val)?.length === ACCOUNT_MAX_LENGTH
      )
      .when('bic', (bic: string[], schema) => {
        if (!!bic?.[0]) {
          return schema.test(
            'validCorAccount',
            i18n.t('AGENT_BANK_DETAILS:errors.corAccountInvalid'),
            (val) => checkKS(removeSpaces(val), bic[0])
          );
        }

        return schema;
      }),
  })
  .required();
