import React, { memo } from 'react';

import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { LinkButton, PASSWORD_RECOVERY, addTestAttribute } from '@shared/index';

export const PasswordRecoveryButton = memo(() => {
  const { t } = useTranslation();

  return (
    <Link to={PASSWORD_RECOVERY}>
      <LinkButton
        variant="text"
        label={t('PASSWORD_RECOVERY_BUTTON:buttons.forgot-your-password')}
        {...addTestAttribute('login.button.passwordRecovery')}
      />
    </Link>
  );
});
